@font-face {
  font-family: "Netherland";
  src: url(./Fonts/Netherland.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'Poppins', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: #24282a;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  background-color: hsla(0, 0%, 100%, 0);
  border: none;
  cursor: pointer;
}

button:focus-visible {
  outline: none !important;
}
button:focus {
  background: transparent !important;
}

.carritoPadre {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
}
.carritoPadreInvisible {
  display: none;
}
.carrito {
  padding: 50px 20px;
  background: #fff;
  position: absolute;
  z-index: 99999;
  right: 20px;
}
.carritoInvisible {
  display: none;
}
.enlace_desactivado {
  pointer-events: none;
  cursor: default;
}
.div-negro {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.div-negro-invisible,
.invisible {
  display: none;
}
.visible {
  display: block;
}
.menu {
  margin-top: 20px;
}
.menu a {
  position: relative;
  display: block;
  color: #787780;
  font-size: 22px;
}
.menu a:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, #9ad8d6, #9ad8d6, #9ad8d6);
  z-index: 1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}
.menu a:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.menu[data-animation='to-left'] a:before {
  -webkit-transform-origin: right;
  transform-origin: right;
}
.menu[data-animation='center'] a:before {
  -webkit-transform-origin: center;
  transform-origin: center;
}
.menu[data-animation='bonus'] a:before {
  -webkit-transform-origin: right;
  transform-origin: right;
}
.menu[data-animation='bonus'] a:hover:before {
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.82, 0.94);
}
.menu > li > a {
  color: #787780;
  font-size: 22px;
}
.menu > li > a:hover {
  color: #000;
  background-color: #fff;
}
.redes-header {
  width: 50px;
  height: 50px;
  background-color: #a51d39;
  margin: 0 5px;
  border-radius: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.redes-header:hover {
  background-color: rgba(165, 29, 56, 0.7254901960784313);
  border: 2px solid #a51d39;
}
.lugares,
.redes-header:hover {
  transition: all 0.5s ease-in-out;
}
.lugares {
  box-shadow: 0 20px 37px -7px rgba(0, 0, 0, 0.75);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  border-radius: 30px;
}
.lugares > img {
  border-radius: 5px;
}
.lugares:hover {
  box-shadow: 0 28px 43px -4px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease-in-out;
}
.titulo_1 {
  font-size: 45px;
  margin: 0;
}
.titulo_2 {
  font-size: 40px;
  font-family: 'Paytone One', sans-serif;
  color: #a51d39;
  margin: -10px 0;
}
.titulo_3 {
  font-size: 35px;
  margin: 0;
}
.titulo_4 {
  font-size: 100px;
  color: #002849;
  line-height: 100px;
}
.titulo_5 {
  font-size: 100px;
  font-weight: 700;
  color: #fff;
}
.fondo {
  background-image: url('./Images/Img/3.png');
  background-position: 50%;
  background-repeat: no-repeat;
}
.fondo,
.pr_fondo_1 {
  width: 100%;
  height: 785px;
}
.pr_fondo_1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  z-index: 99;
}
.fondo_1 {
  background-image: url('./Images/Img/16.png');
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-filter: brightness(0.6);
  filter: brightness(0.6);
}
.fondo_1,
.pr_fondo_2 {
  width: 100%;
  height: 785px;
}
.pr_fondo_2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  position: absolute;
  z-index: 99;
}
.fondo_2 {
  width: 100%;
  height: 785px;
  background-image: url('./Images/Img/21.png');
  background-position: 50%;
  background-repeat: no-repeat;
  -webkit-filter: brightness(0.6);
  filter: brightness(0.6);
}
.btn_1 {
  background: none !important;
  border: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin: 0;
  padding: 0;
}
.boton {
  margin-top: 30px;
}
.boton > div {
  background: #9ad8d6;
  width: 200px;
  height: 70px;
  font-size: 25px;
  font-weight: 700;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: #000;
  border-radius: 50px;
}
.boton:hover > div,
.boton > div {
  transition: all 0.5s ease-in-out;
}
.boton:hover > div {
  background: #fff;
  border: 3px solid #9ad8d6;
}
.boton_2 {
  
  margin-top: 30px;
}
.boton_2 > div {
  background: #a51d39;
  width: 200px;
  height: 70px;
  font-size: 25px;
  font-weight: 700;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: #fff ;
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
}
.boton_2:hover > div {
  background: #fff;
  color: #a51d39;
  border: 3px solid #a51d39;
  transition: all 0.5s ease-in-out;
}
.boton_3 {
  margin-top: 30px;
}
.boton_3 > div {
  background: #a51d39;
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-weight: 700;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  color: #fff;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.boton_3:hover > div {
  background: hsla(0, 0%, 100%, 0);
  color: #a51d39;
  border: 3px solid #a51d39;
  transition: all 0.5s ease-in-out;
}
.padre,
.padre_1 {
  display: -webkit-flex;
  display: flex;
}
.padre_2 {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.padre_2,
.padre_3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}
.padre_3 {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.sombra {
  box-shadow: 0 20px 37px -7px rgba(0, 0, 0, 0.75);
}
textarea {
  height: 200px;
}
.input_contacto {
  background: #9ad8d6 !important;
  color: #000 !important;
  border-radius: 10px !important;
  padding-left: 5px !important;
  font-size: 20px;
}
.input_contacto::placeholder {
  color: #000;
  font-size: 20px;
}
.input_contacto::-webkit-input-placeholder {
  color: #000;
  font-size: 20px;
}
#formGroupExampleInput::-moz-placeholder {
  color: #000;
  font-size: 20px;
}
.input_contacto:-ms-input-placeholder {
  color: #000;
  font-size: 20px;
}
.input_contacto:-moz-placeholder {
  color: #000;
  font-size: 20px;
}
.imagen_filter {
  width: 50px;
  height: 50px;
  transition: all 0.5s ease-in-out;
}
.imagen_filter:hover {
  -webkit-filter: drop-shadow(0 2px 2px rgba(165, 29, 56, 0.5215686274509804));
  filter: drop-shadow(0 2px 2px #a51d3885);
}
.imagen_filter:hover,
.imagen_filter_1 > div > img {
  transition: all 0.5s ease-in-out;
}
.imagen_filter_1:hover > div > img {
  -webkit-filter: drop-shadow(0 2px 2px rgba(165, 29, 56, 0.5215686274509804));
  filter: drop-shadow(0 2px 2px #a51d3885);
  transition: all 0.5s ease-in-out;
}
.imagen_filter_2 {
  width: 50px;
  height: 50px;
  transition: all 0.5s ease-in-out;
}
.imagen_filter_2:hover {
  -webkit-filter: drop-shadow(0 4px 4px hsla(0, 0%, 100%, 0.5019607843137255));
  filter: drop-shadow(0 4px 4px #ffffff80);
}
.imagen_filter_2:hover,
.imagen_filter_3 > div > img {
  transition: all 0.5s ease-in-out;
}
.imagen_filter_3:hover > div > img {
  -webkit-filter: drop-shadow(0 4px 4px hsla(0, 0%, 100%, 0.5019607843137255));
  filter: drop-shadow(0 4px 4px #ffffff80);
  transition: all 0.5s ease-in-out;
}
.hijo_1 > p {
  margin: 0;
}
.texto_absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.div_ventas_7 {
  border: 1px solid grey;
  width: 100%;
}
.div_ventas_5,
.div_ventas_6 {
  border: 1px solid grey;
  width: 50%;
  border-bottom: 0;
}
.div_ventas_5 {
  border-right: 0;
}
.div_ventas_3,
.div_ventas_4 {
  border: 1px solid grey;
  width: 50%;
  border-top: 0;
  border-bottom: 0;
}
.div_ventas_3 {
  border-right: 0;
}
.div_ventas_1,
.div_ventas_2 {
  border: 1px solid grey;
  width: 50%;
}
.div_ventas_1 {
  border-right: 0;
}
.titulo_ventas {
  font-size: 30px;
}
.parrafo_1,
.titulo_ventas {
  margin: 0;
  padding: 15px;
}
.nav-formulario {
  box-shadow: none;
}
.nav-wrapper-formulario {
  background: #fff;
  padding: 0 5%;
}
.nav-wrapper-formulario > div > a {
  color: #000 !important;
  font-weight: 700;
}
.breadcrumb:before {
  color: #030303;
}
.margin_1 {
  margin: 100px;
}
.titulo_destiunos {
  font-size: 30px;
  color: #000;
  margin: 20px 0;
}
.padding_movil {
  padding: 100px 0;
}
.servicios_index {
  font-size: 30px;
  text-align: left;
  margin-top: 50px;
}
.margin_membresias {
  margin: 200px 0;
}

.contenedor_1{
  padding: 0px 10%;
}
.destinos{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.flex_1{
  width: 22.5%;
  height: 737px;
  margin: 2%;
  box-shadow: 0px 7px 19px -5px rgba(0,0,0,0.75);
  border-radius: 30px 30px 30px 30px;
}
.destinos img{
  width: 100%;
  
  height: 487px;
  border-radius: 10px 10px 10px 10px;
}
.destinos p{
  font-size: 26px;
  margin: 20px;
  background-color: white;
  color: #313041;
  border-radius: 0px 0px 30px 30px;
}
.destinos h1{
  font-size: 25px;
  color: #a51d39;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
}


@media only screen and (min-width: 200px) and (max-width: 992px) {
  .logo_header {
    width: 100%;
    position: absolute;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin: 16px 0 !important;
    z-index: 1;
  }
}
@media only screen and (min-width: 200px) and (max-width: 550px) {
  .fondo {
    background-image: url('./Images/Img/3_2.png') !important;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .margin_membresias {
    margin: 100px 0;
  }
  .membresias_1 {
    height: 395px !important;
  }
  .nav {
    height: 90px !important;
  }
  .header_2 {
    height: 240px !important;
  }
  .servicios_index {
    font-size: 20px;
    text-align: left;
    margin-top: 50px;
  }
  .servicios_index > div > img {
    width: 80px !important;
    margin: 10px 0;
  }
  .padding_movil {
    padding: 50px 0;
  }
  .lugares > p {
    font-size: 17px !important;
  }
  .fondo_1,
  .fondo_2,
  .pr_fondo_1,
  .pr_fondo_2 {
    height: 750px;
  }
  .fondo{
    height: 400px;
  }
  .membresias_1,
  .membresias_2 {
    background-color: #fff;
    padding: 0 15px;
  }
  .titulo_destiunos {
    font-size: 15px;
    color: #000;
    margin: 20px 0;
  }
  .texto_absolute {
    position: absolute;
    left: 50%;
    top: 23%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .titulo_5 {
    font-size: 50px;
    font-weight: 700;
    color: #fff;
  }
  .titulo_4 {
    margin-top: 0;
    font-size: 65px !important;
    line-height: 70px;
    margin-top: 35px;
  }
  .margin_1 {
    margin: 50px 0;
  }
  .titulo_2 {
    font-size: 30px;
    text-align: center;
  }
  .centrar_flex {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .movil,
  .movil_1 {
    display: none !important;
  }
  .movil_visible {
    display: inline;
  }
  .padre_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .padre {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .hijo,
  .padre {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
  }
  .hijo {
    width: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo > img {
    width: 80% !important;
    margin-bottom: 25px;
  }
  .hijo_1 {
    height: 500px;
  }
  .hijo_1,
  .hijo_2 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_3 {
    width: 40%;
    margin: 30px 10px;
  }
  .contenedor_galeria {
    width: 150px;
    height: 150px;
  }
  .contenedor_galeria > .material-placeholder {
    height: 150px;
  }
  .contenedor_galeria > .material-placeholder > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .imagen_galeria.active {
    object-fit: contain !important;
  }
}
@media only screen and (min-width: 551px) and (max-width: 850px) {
  .nav {
    height: 90px !important;
  }
  .membresias_1,
  .membresias_2 {
    background-color: #fff;
    padding: 0 15px;
  }
  .movil,
  .movil_visible {
    display: none !important;
  }
  .padre_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo {
    width: 50%;
  }
  .hijo,
  .hijo_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_1 {
    width: 100%;
    height: 500px;
  }
  .hijo_2 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_3 {
    width: 27%;
    margin: 50px 20px;
  }
  .contenedor_galeria {
    width: 300px;
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder {
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .imagen_galeria.active {
    object-fit: contain !important;
  }
}
@media only screen and (min-width: 851px) and (max-width: 1399px) {
  .fondo,
  .fondo_1,
  .fondo_2,
  .pr_fondo_1,
  .pr_fondo_2 {
    height: 580px;
  }
  .nav {
    height: 190px;
  }
  .membresias_1,
  .membresias_2 {
    background-color: #fff;
    position: relative;
  }
  .movil_visible {
    display: none !important;
  }
  .menu > li > a,
  .movil > div > p {
    font-size: 15px;
  }
  .hijo {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_1 {
    height: 500px;
  }
  .hijo_1,
  .hijo_2 {
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_3 {
    width: 27%;
    margin: 50px 20px;
  }
  .contenedor_galeria {
    width: 300px;
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder {
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .imagen_galeria.active {
    object-fit: contain !important;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1724px) {
  .nav {
    height: 190px;
  }
  .membresias_1 {
    right: -100px;
  }
  .membresias_1,
  .membresias_2 {
    background-color: #fff;
    position: relative;
    top: 150px;
  }
  .membresias_2 {
    left: -100px;
  }
  .movil_visible {
    display: none !important;
  }
  .movil > div > p {
    font-size: 15px;
  }
  .hijo {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_1 {
    height: 500px;
  }
  .hijo_1,
  .hijo_2 {
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_3 {
    width: 300px;
    margin: 50px 20px;
  }
  .contenedor_galeria {
    width: 300px;
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder {
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .imagen_galeria.active {
    object-fit: contain !important;
  }
}
@media only screen and (min-width: 1725px) {
  .nav {
    height: 190px;
  }
  .membresias_1 {
    right: -200px;
  }
  .membresias_1,
  .membresias_2 {
    background-color: #fff;
    position: relative;
    top: 150px;
  }
  .membresias_2 {
    left: -200px;
  }
  .movil_visible {
    display: none !important;
  }
  .hijo {
    width: 50%;
  }
  .hijo,
  .hijo_1 {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_1 {
    width: 40%;
    height: 500px;
  }
  .hijo_2 {
    width: 60%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .hijo_3 {
    width: 300px;
    margin: 50px 20px;
  }
  .contenedor_galeria {
    width: 300px;
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder {
    height: 300px;
  }
  .contenedor_galeria > .material-placeholder > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  .imagen_galeria.active {
    object-fit: contain !important;
  }
}
